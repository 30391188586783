import "../Style/App.css";
import Header from "./header";
import Morgane from "./Morgane";
import { Footer } from "./Footer";
import { Menu } from "./Menu";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    const body = document.getElementById("root");
    const handleMouseMove = (event) => {
      // Obtenir la position du curseur sur la page (les autres options sont bugger)
      const { pageX, pageY } = event;

      let star = document.createElement("span");

      let startSize = Math.random() * 16;
      let starRotate = Math.random() * 360;

      //config et style css
      star.className = "mousse-animation";
      star.style.left = pageX + "px";
      star.style.top = pageY + "px";
      star.style.fontSize = startSize + 10 + "px";
      star.style.rotate = starRotate + "deg";

      body.appendChild(star);

      setTimeout(function () {
        star.remove();
      }, 1000);
    };

    // Ajouter un écouteur d'événement pour suivre le mouvement du curseur
    document.addEventListener("mousemove", handleMouseMove);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="content-container">
        <Morgane />
        <Menu />
      </div>
      <Footer />
    </div>
  );
}

export default App;
