import { skills } from '../Assets/img/skills/skillsIndex';
import '../Style/Skills.css';

export function Skills() {
  let delai = 400;
  function setAnimanitionSkillDelai() {
    delai += 200;
    return delai;
  }

  const skillsList = skills.map((skill) => (
    <img
      src={skill.image}
      alt={skill.name}
      key={skill.name}
      className="skill"
      style={{
        animation: 'upAndShadow infinite alternate 1s',
        animationDelay: `${setAnimanitionSkillDelai()}ms`,
      }}
    />
  ));
  console.log('is repete ? ');
  return (
    <div id="skills" className="skills">
      {skillsList}
    </div>
  );
}
