export const projetsIndex = [
  {
    projet: {
      id: 10,
      title: 'MogDashboard.app',
      img: require('../Assets/img/projets/morg.png'),
      description: [
        'APP fullstak de gestion de facture',
        'Next.js / Typescript',
        'Déploiment sur vercel (serverless)',
        'Création et gestion de base de donnée',
        'Authentification cryptée',
      ],
      link: 'https://mogdashboard.vercel.app/dashboard',
      isScolaire: false,
      isOnline: true,
    },
  },
  {
    projet: {
      id: 11,
      title: 'The WellHall',
      img: require('../Assets/img/projets/theWellhall.png'),
      description: [
        'Site static React / next',
        'Déploiment sur le web',
        'Création du site à partir de maquette UI/UX',
        'Mise en production rapide',
      ],
      link: 'https://www.morgane-picciotto.fr/TheWellhall/',
      isScolaire: false,
      isOnline: true,
    },
  },
  {
    projet: {
      id: 9,
      title: 'Kawaii Eater',
      img: require('../Assets/img/projets/kawaiiEater.png'),
      description: [
        'Démo de jeux ludiques',
        'next.js / typescript',
        'Contrôle des états des composants',
        'optimisation',
        'Déployment en ligne',
        'animations',
      ],
      link: 'https://www.morgane-picciotto.fr/KawaiiEater/',
      isScolaire: false,
      isOnline: true,
    },
  },
  {
    projet: {
      id: 1,
      title: 'Picciotto-xm.tech',
      img: require('../Assets/img/projets/picciotto-xm.png'),
      description: [
        "Configuration de A à Z d'un vps Ubuntu 20.04 avec Ngix et Pm2",
        'CRUD Utilisateur sécurisé.',
        "Envoi d'emails serveur.",
        'Rendu HTML côté serveur.',
        'Database MySQL',
        'projet web de morgane picciotto : réaliser un serveur privé',
      ],
      link: 'https://github.com/xavierpicciotto/picciotto-server',
      isScolaire: false,
      isOnline: false,
    },
  },
  {
    projet: {
      id: 2,
      title: 'Créer un réseau social d’entreprise.',
      img: require('../Assets/img/projets/p6.png'),
      description: [
        'Personnaliser le contenu envoyé à un client web.',
        "Gérer un stockage de données à l'aide de SQL.",
        'Implémenter un stockage de données sécurisé en utilisant SQL.',
        'Authentifier un utilisateur et maintenir sa session.',
      ],
      link: 'https://github.com/xavierpicciotto/Projet-7',
      isScolaire: true,
      isOnline: false,
    },
  },
  {
    projet: {
      id: 3,
      title: "Construire une API sécurisée pour une app d'avis gastronomiques",
      img: require('../Assets/img/projets/p5.png'),
      description: [
        'Mettre en œuvre des opérations CRUD (create, read, update, delete) de manière sécurisée.',
        'Stocker des données de manière sécurisée.',
        'Implémenter un modèle logique de données conformément à la réglementation.',
      ],
      link: 'https://github.com/xavierpicciotto/Projet-6',
      isScolaire: true,
      isOnline: false,
    },
  },
  {
    projet: {
      id: 4,
      title: 'Construire un site de e-commerce',
      img: require('../Assets/img/projets/p4.png'),
      description: [
        'Créer un plan de test pour une application.',
        'Gérer des événements JavaScript.',
        'Interagir avec un web service avec JavaScript.',
        'Valider des données issues de sources externes.',
      ],
      link: 'https://github.com/xavierpicciotto/Orinoco',
      isScolaire: true,
      isOnline: false,
    },
  },
  {
    projet: {
      id: 5,
      title: 'Optimiser un site web existant',
      img: require('../Assets/img/projets/p3.png'),
      description: [
        'Réaliser une recherche des bonnes pratiques en développement web.',
        "Assurer l'accessibilité d'un site web.",
        'Écrire un code HTML et CSS maintenable.',
        'Optimiser la taille et la vitesse d’un site web.',
        "Optimiser le référencement d'un site web.",
      ],
      link: 'https://github.com/xavierpicciotto/La-chouette-testing',
      isScolaire: true,
      isOnline: false,
    },
  },
  {
    projet: {
      id: 6,
      title: 'Dynamiser une page web avec des animations CSS',
      img: require('../Assets/img/projets/p2.png'),
      description: [
        'Créer un site web responsif.',
        'Mettre en place une structure de navigation pour un site web.',
        'Mettre en œuvre des effets CSS graphiques avancés.',
        "Assurer la cohérence graphique d'un site web.",
      ],
      link: 'https://github.com/xavierpicciotto/omyfood',
      isScolaire: true,
      isOnline: false,
    },
  },
  /*{
    projet: {
      id: 7,
      title: "Transformer une maquette en site web",
      img: require("../Assets/img/projets/p1.png"),
      description: [
        "Intégrer du contenu conformément à une maquette.",
        "Utiliser un système de gestion de versions pour le suivi du projet et son hébergement.",
        "Mettre en place son environnement Front-End.",
        "Implémenter une interface responsive.",
      ],
      link: "https://github.com/xavierpicciotto/xavierpicciotto",
      isScolaire: true,
    },
  },*/
  {
    projet: {
      id: 8,
      title: 'ex-Portfolio Vue.js',
      img: require('../Assets/img/projets/portfolioVue.png'),
      description: [
        'Réalisé avec Vue.js.',
        'Vuex et vue-router.',
        "Gestion d'envoi et reception des donées pour l'Api back-end Picciotto-xm.tech",
        "Mise en ligne et supervision d'un site web.",
      ],
      link: 'https://github.com/xavierpicciotto/Mon-Portfolio',
      isScolaire: false,
      isOnline: false,
    },
  },

  /*{projet:{
        id:"",
        title:"",
        img:require(""),
        description:[],
        link:"",
        isScolaire: true,
        isOnline: false,
    }},*/
];
