import '../Style/Menu.css';
import React, { useState } from 'react';
import { Selection } from './Selection';

export function Menu() {
  const [userSelect, setUserSelect] = useState('Select a button');
  const selection = document.getElementById('selection');

  const scrollMenuButton = () => {
    window.scrollTo({
      top: selection.scrollHeight,
      behavior: 'smooth',
    });
    selection.scrollTop = 0;
  };

  const handleSelect = (e) => {
    setUserSelect(e.target.id);
    scrollMenuButton();
  };

  return (
    <div className="menu">
      <div className="menu-interface">
        <button
          onClick={handleSelect}
          className="menu-button cv"
          id="cvButton"
          type="button"
        >
          CV
          <div className="liquid liquid-cv"></div>
        </button>
        <button
          onClick={handleSelect}
          className="menu-button scolaire"
          id="scolaireButton"
          type="button"
        >
          Projet Scolaire
          <div className="liquid liquid-scolaire"></div>
        </button>
        <button
          onClick={handleSelect}
          className="menu-button perso"
          id="persoButton"
          type="button"
        >
          Projet Pro
          <div className="liquid"></div>
        </button>
      </div>
      <Selection
        userSelect={userSelect}
        setUserSelect={setUserSelect}
      ></Selection>
    </div>
  );
}
