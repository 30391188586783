import '../Style/Selection.css';
import { projetsIndex } from '../Utils/indexProjets';
const cvPnj = require('../Assets/img/Morgane Picciotto.jpg');
const cvPdf = require('../Assets/Morgane Picciotto.pdf');

//Filtre la liste des projets.
function projetsListIsScolaire(boolean) {
  return projetsIndex.filter((e) => e.projet.isScolaire === boolean);
}

//Creation d'une liste JSX de projets.
const creatProjectList = (filteredList) => {
  function buildProjectCard(el) {
    return (
      <div className="projet glassmorphisme" key={el.projet.id}>
        <img className="projet-img" src={el.projet.img} alt="" />
        <h4 className="projet-title">{el.projet.title}</h4>
        {el.projet.description.map((el) => (
          <p className="projet-description" key={el}>
            {el}
          </p>
        ))}
        <a className="projet-link" href={el.projet.link}>
          <button
            className={`projet-linkButton ${
              el.projet.isOnline ? 'website-linkButton' : 'github-linkButton'
            }`}
            type="button"
          >
            {el.projet.isOnline
              ? "Testez ici c'est en ligne!"
              : 'Voir le Github'}
          </button>
        </a>
      </div>
    );
  }
  const projetsListJsx = filteredList.map((projet) => buildProjectCard(projet));
  return (
    <div className="projets-list-container" id="projets-list-container">
      {projetsListJsx}
    </div>
  );
};

//Rendu des listes.
const projetsListScolaire = creatProjectList(projetsListIsScolaire(true));
const projetsListPerso = creatProjectList(projetsListIsScolaire(false));

export function Selection({ userSelect, setUserSelect }) {
  const showSelection = (show) => {
    switch (show) {
      case 'cvButton':
        return (
          <div className="cv-box">
            <img
              src={cvPnj}
              onClick={() => {
                window.open(cvPdf);
              }}
              className="cvPng"
              alt="cv de morgane"
            ></img>
            <a href={cvPdf} download={'Morgane Picciotto.pdf'}>
              <button className="cv-button download-cv">Télecharger</button>
            </a>
          </div>
        );
      case 'scolaireButton':
        return projetsListScolaire;

      case 'persoButton':
        return projetsListPerso;

      default:
        return (
          <div className="selection-default">
            <h1>&#8679; Select a option &#8679;</h1>
          </div>
        );
    }
  };

  return (
    <div id="selection" className="selection">
      {showSelection(userSelect)}
    </div>
  );
}
