export const skills = [
  { name: "html", image: require("./html.jpg") },
  { name: "css", image: require("./css.png") },
  { name: "js", image: require("./js.jpg") },
  { name: "reactJs", image: require("./reactJs.png") },
  { name: "express", image: require("./express.png") },
  { name: "mySql", image: require("./mysql.jpg") },
  { name: "nodeJs", image: require("./nodeJs.jpg") },
  { name: "github", image: require("./github.jpg") },
];
