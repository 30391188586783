import React from 'react';
import me from '../Assets/img/me.png';
import '../Style/Morgane.css';
import { Skills } from './Skills';

function Morgane() {
  return (
    <section className="presentation">
      <div className="photo-name">
        <img src={me} className="maphoto" alt="Morgane Picciotto" />
        <h2 className="name">Morgane Picciotto</h2>
      </div>
      <div className="presentation-text-box">
        <p className="presentation-text">
          Je suis développeuse Web diplômée par Openclassroom, spécialisée en
          React et Typescript. Passionnée par mon travail, je suis
          professionnelle, investie et rigoureuse dans mes projets. En dehors du
          travail, mes proches me décrivent comme une personne sociable, ouverte
          d'esprit et passionnée par les sciences et les jeux vidéo. Je suis
          animée par la volonté de bien faire, tant sur le plan professionnel
          que personnel, et je suis toujours prête à relever de nouveaux
          défis."Oui j'aime faire des animations"
        </p>
      </div>
      <Skills></Skills>
    </section>
  );
}

export default Morgane;
